const Store = (props) => {

    return (
        <>
            <iframe src="https://player.beatstars.com/?storeId=123157" width="100%" height="1050" > -- none -- </iframe>
            <div class="div">
                <a  href="/" className='button btn'>Go Back</a>
            </div>
        </>
    )
}
export default Store;