import React, { useState, useEffect } from 'react';
import Container from 'bootstrap'
import { Button } from 'react-bootstrap';
import {divWithClassName} from 'react-bootstrap';
import tree from '../images/tree.jpeg';
import game from '../images/game.jpeg';
import abyss from '../images/blackhole.jpeg';
import smoke from '../images/smoke.gif'
import Leaves from '../leaves'
import { Divider } from '@material-ui/core';






const Main = (props) => {

    return (
        <>
            <div id="container">


                <div className="banner">
                    <img src={tree} onHover={true} className='Image' /><Leaves />
                </div>
                <divWithClassName className="bottom ">
                    <div class="img_icons">

                        <img src={game}></img>
                        <h3>Games</h3>
                    </div>
                    <div className="img_icons">
                        <img src={abyss}></img>
                        <h3>Music</h3>

                    </div>
                    <div className="img_icons moon" id="moon">
                        <img src={smoke} shader="blink" alt=""></img>
                        <h3>Crypto</h3>
                    </div>

                </divWithClassName>
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="business" value="UDH9FPZCBKTMA" />
                    <input type="hidden" name="no_recurring" value="0" />
                    <input type="hidden" name="item_name" value="Future Development/site additions" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>

                <divWithClassName className='btn2 crypto'>
                    <a class="donate-with-crypto"
                        href="https://commerce.coinbase.com/checkout/7689c871-dadf-4f46-b36b-6bbdd6e32c57">
                        Donate with Crypto
                    </a>
                    <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
                    </script>
                </divWithClassName>
                <a class="mailto" href="mailto:lxrdhoshi@gmail.com"><Button>Inquiry </Button></a>
            </div>
        </>
    )
}
export default Main;