const Spotify = (props) => {

    return (
        <>
            <iframe src="https://open.spotify.com/embed/artist/5hn2HSY64qtX4cMxR5wDtI?utm_source=generator" width="100%" height="900px" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <a className='btn button' href="/">Go Back</a>

        </>
    )
}
export default Spotify;




